import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SchedCard from "../components/SchedCard";

function Sched() {
  const schedule = {
    MON: [
      { time: "8:00 am", classTitle: "KICKBOXING", type: "ADULTS" },
      { time: "9:00 am", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "10:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "11:00 am", classTitle: null },
      { time: "12:00 pm", classTitle: null },
      { time: "3:50 pm", classTitle: "KICKBOXING", type: "KIDS" },
      { time: "4:30 pm", classTitle: "JIU-JITSU", type: "KIDS 1 | 6-9 YRS" },
      { time: "5:30 pm", classTitle: "JIU-JITSU", type: "KIDS 1 | 9-12 YRS" },
      { time: "6:30 pm", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "7:30 pm", classTitle: "JIU-JITSU", type: "ADVANCED" },
    ],
    TUE: [
      { time: "8:00 am", classTitle: null },
      { time: "9:00 am", classTitle: null },
      { time: "10:00 am", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "11:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "12:00 pm", classTitle: null },
      {
        time: "3:50 pm",
        classTitle: "JIU-JITSU",
        type: "LITTLE CHAMPIONS | 4-5 YRS",
      },
      { time: "4:30 pm", classTitle: "JIU-JITSU", type: "KIDS 2 | 6-9 YRS" },
      { time: "5:30 pm", classTitle: "JIU-JITSU", type: "KIDS 2 | 9-12 YRS" },
      { time: "6:30 pm", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "7:30 pm", classTitle: "JIU-JITSU", type: "ADVANCED" },
    ],
    WED: [
      { time: "8:00 am", classTitle: "KICKBOXING", type: "ADULTS" },
      { time: "9:00 am", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "10:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "11:00 am", classTitle: null },
      { time: "12:00 pm", classTitle: null },
      { time: "3:50 pm", classTitle: null },
      { time: "4:30 pm", classTitle: "JIU-JITSU", type: "KIDS 1 | 6-9 YRS" },
      { time: "5:30 pm", classTitle: "JIU-JITSU", type: "KIDS 1 | 9-12 YRS" },
      { time: "6:30 pm", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "7:30 pm", classTitle: "JIU-JITSU", type: "ADVANCED" },
    ],
    THU: [
      { time: "8:00 am", classTitle: null },
      { time: "9:00 am", classTitle: null },
      { time: "10:00 am", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "11:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "12:00 pm", classTitle: null },
      {
        time: "3:50 pm",
        classTitle: "JIU-JITSU",
        type: "LITTLE CHAMPIONS | 4-5 YRS",
      },
      { time: "4:30 pm", classTitle: "JIU-JITSU", type: "KIDS 2 | 6-9 YRS" },
      { time: "5:30 pm", classTitle: "JIU-JITSU", type: "KIDS 2 | 9-12 YRS" },
      { time: "6:30 pm", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "7:30 pm", classTitle: "JIU-JITSU", type: "ADVANCED" },
    ],
    FRI: [
      { time: "8:00 am", classTitle: "KICKBOXING", type: "ADULTS" },
      { time: "9:00 am", classTitle: "JIU-JITSU", type: "FUNDAMENTALS" },
      { time: "10:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "11:00 am", classTitle: "OPEN MAT" },
      { time: "12:00 pm", classTitle: null },
      { time: "3:50 pm", classTitle: "KICKBOXING", type: "KIDS" },
      { time: "4:30 pm", classTitle: "KICKBOXING", type: "ADULTS" },
      { time: "5:30 pm", classTitle: null },
      { time: "6:30 pm", classTitle: null },
      { time: "7:30 pm", classTitle: null },
    ],
    SAT: [
      { time: "8:00 am", classTitle: null },
      { time: "9:00 am", classTitle: "NO-GI", type: "KIDS + JUNIORS" },
      { time: "10:00 am", classTitle: "KICKBOXING", type: "KIDS" },
      { time: "11:00 am", classTitle: "JIU-JITSU", type: "ADVANCED" },
      { time: "12:00 pm", classTitle: "OPEN MAT" },
      { time: "3:50 pm", classTitle: null },
      { time: "4:30 pm", classTitle: null },
      { time: "5:30 pm", classTitle: null },
      { time: "6:30 pm", classTitle: null },
      { time: "7:30 pm", classTitle: null },
    ],
  };

  const getWeekDay = () => {
    const date = new Date();
    const dayIndex = date.getDay();
    return ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][dayIndex];
  };

  const currentDay = getWeekDay();

  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const currentDate = getCurrentDate();

  useEffect(() => {
    const checkLastReload = () => {
      const lastReload = localStorage.getItem("lastReloadTime");
      const now = Date.now();

      if (!lastReload || now - parseInt(lastReload, 10) > 3600000) {
        // If more than 1 hour has passed, reload the page
        localStorage.setItem("lastReloadTime", now.toString());
        window.location.reload();
      }
    };

    // Check when the page is focused (when returning to the tab)
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        checkLastReload();
      }
    });

    // Also check on initial page load
    checkLastReload();

    return () => {
      document.removeEventListener("visibilitychange", checkLastReload);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>CJJF Schedule</title>
      </Helmet>
      <div
        className="container-xxl py-3"
        style={{ backgroundColor: "rgb(230,230,230)" }}
      >
        <div className="text-center mb-3">
          <h2>{currentDate}</h2> {/* Display the current date */}
        </div>
        <div className="row justify-content-center">
          {Object.entries(schedule).map(([day, classes]) => (
            <div
              className={`col-2 text-center p-1 p-md-2 ${
                currentDay === day ? "current-day-border" : ""
              }`}
              key={day}
            >
              <h1
                className={`${
                  currentDay === day ? "dayBG" : "bg-black"
                } text-white dayTitle`}
              >
                {day}
              </h1>
              {classes.map((classInfo, index) => (
                <SchedCard
                  key={index}
                  time={classInfo.time}
                  classTitle={classInfo.classTitle}
                  type={classInfo.type}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Sched;
