import Home from "./pages/home/Home.jsx";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ContactUs from "./pages/contact-us/ContactUs";
import Events from "./pages/events/Events";
import Information from "./pages/information/Information";
import OurSponsors from "./pages/our-sponsors/OurSponsors";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./pages/Login.js";
import Register from "./pages/Register.js";
import RegisterAcademy from "./pages/RegisterAcademy.js";
import Blackbelts from "./pages/Blackbelts.js";
import Academies from "./pages/Academies.js";
import WhatsAppChat from "./components/whatsAppIcon.js";
import Dashboard from "./pages/Dashboard.js";
import Sched from "./pages/Sched.js";
import { useAuth } from "./contexts/AuthContext";

function Layout({ children }) {
  const location = useLocation();
  const noHeaderFooterRoutes = ["/sched"];

  return (
    <>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}
      {children}
      {!noHeaderFooterRoutes.includes(location.pathname) && (
        <>
          <Footer />
          <WhatsAppChat />
        </>
      )}
    </>
  );
}

function App() {
  const { user, loading } = useAuth(); // Use the user from AuthContext

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/our-sponsors" element={<OurSponsors />} />
          <Route path="/information" element={<Information />} />
          <Route path="/events" element={<Events />} />
          <Route path="/sched" element={<Sched />} />
          <Route path="*" element={<Home />} />
          <Route
            path="/register"
            element={user ? <Navigate to="/dashboard" /> : <Register />}
          />
          <Route path="/black-belts" element={<Blackbelts />} />
          <Route path="/academies" element={<Academies />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={user ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/register-academy"
            element={
              user && user.approved ? <RegisterAcademy /> : <Navigate to="/" />
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
