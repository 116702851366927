import React from "react";

function SchedCard({ time, classTitle, type }) {
  return (
    <div className="time-slot d-flex flex-column">
      <p className="time mb-0">{time}</p>
      <div className="d-flex flex-column justify-content-center flex-grow-1">
        {classTitle ? (
          <>
            <p className="mb-0 fw-semibold classTitle">{classTitle}</p>
            {type && <p className="classType fw-semibold mb-0">{type}</p>}
          </>
        ) : (
          <p className="classTitle mb-0">No Class</p>
        )}
      </div>
    </div>
  );
}

export default SchedCard;
